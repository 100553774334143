import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-ae2992d8"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "content"
};
import { onMounted, reactive } from "vue";
import GoldListItem from "@@/score/goldListItem";
import { getGoldHistoryList } from "@/api";
import { mdToast } from "../../utils/tools";
import Error from "@@/common/Error.vue";
export default {
  setup(__props) {
    const params = reactive({
      page: 1,
      page_size: 20,
      list: [],
      refreshing: false,
      is_next: true,
      loading: false
    });
    onMounted(() => {
      document.title = '明细';
      onRefresh();
    });

    //下拉刷新
    function onRefresh() {
      params.page = 1;
      sendGoldListRequest();
    }

    // 加载更多
    function onLoadMore() {
      sendGoldListRequest();
    }
    async function sendGoldListRequest() {
      params.loading = true;
      const {
        status,
        data,
        message
      } = await getGoldHistoryList({
        page: params.page,
        page_size: params.page_size
      });
      params.loading = false;
      params.refreshing = false;
      params.is_next = data.is_next;
      if (status == 0) {
        if (params.page == 1) {
          params.list.splice(0, params.list.length);
        }
        if (data.list && data.list.length > 0) {
          params.list.push(...data.list);
          params.page++;
        }
      } else {
        mdToast(message);
      }
    }
    return (_ctx, _cache) => {
      const _component_van_list = _resolveComponent("van-list");
      const _component_van_pull_refresh = _resolveComponent("van-pull-refresh");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_unref(params).list.length > 0 ? (_openBlock(), _createBlock(_component_van_pull_refresh, {
        key: 0,
        modelValue: _unref(params).refreshing,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _unref(params).refreshing = $event),
        onRefresh: onRefresh
      }, {
        default: _withCtx(() => [_createVNode(_component_van_list, {
          loading: _unref(params).loading,
          "onUpdate:loading": _cache[0] || (_cache[0] = $event => _unref(params).loading = $event),
          finished: !_unref(params).is_next,
          "immediate-check": false,
          onLoad: onLoadMore
        }, {
          default: _withCtx(() => [_createVNode(_unref(GoldListItem), {
            list: _unref(params).list
          }, null, 8, ["list"])]),
          _: 1
        }, 8, ["loading", "finished"])]),
        _: 1
      }, 8, ["modelValue"])) : !_unref(params).loading ? (_openBlock(), _createBlock(Error, {
        key: 1,
        message: "什么都没有哦",
        class: "empty-box"
      })) : _createCommentVNode("", true)]);
    };
  }
};