import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-056cfd80"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "left"
};
const _hoisted_2 = {
  class: "time"
};
import { onMounted } from "vue";
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  setup(__props) {
    const props = __props;
    onMounted(() => {
      console.log('props002-----', props);
    });
    return (_ctx, _cache) => {
      return _openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.list, (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          class: "item",
          key: index
        }, [_createElementVNode("div", _hoisted_1, [_createTextVNode(_toDisplayString(item.subject) + " ", 1), _createElementVNode("div", _hoisted_2, _toDisplayString(item.success_time), 1)]), _createElementVNode("div", {
          class: _normalizeClass(['num', {
            'cut': item.trade_type == 2
          }])
        }, _toDisplayString(item.trade_type == 2 ? '-' : '+') + _toDisplayString(item.amount), 3)]);
      }), 128);
    };
  }
};